
import { ref } from 'vue'
import { createUser, User } from 'momai'
import { useRoute } from 'vue-router'
import { detail as fetchUserInfo, delUserById } from '@/api/user'
import { fetchApplicants } from '@/states/login'
import { goBack } from '@/states/layout'
import { Toast, Dialog } from 'vant'

export default {
  setup: () => {
    const applicantDetail = ref<User>(createUser())
    const { userId } = useRoute().params

    fetchUserInfo(userId).then(r => {
      applicantDetail.value = r
    })
    return {
      applicantDetail,
      fetchApplicants,
      delUser: () => {
        Dialog.confirm({
          title: '确认',
          message: '确认删除吗？'
        }).then(() => {
          delUserById(Number(userId)).then(() => {
            Toast.success('申请人删除成功！')
            goBack()
          })
        }).catch(() => {})
      }
    }
  }
}
